<template>
	<div class="row-space-tbf opacity-page">
		<div class="space-left"></div>
		<div class="content" v-if="loaded">
			<div class="list-improvements" v-if="improvementsData.improvements.length">
				<div class="improvement-item" v-for="improvement in improvementsData.improvements">
					<div class="top-data">
						<div class="left-data">
							<div class="user">
								<div class="image">
									<img :src="improvement.user.avatar" v-if="improvement.user.avatar">
									<div class="user-circle" v-else>
										<icon-user />
									</div>
									<div class="status">
										<div class="circle-status" v-bind:class="{green: improvement.status == 'active', blue: improvement.status == 'featuring', orange: improvement.status == 'archived'}"></div>
									</div>
								</div>
								<div class="data">
									<div class="tag">{{ improvement.created_at | moment("DD MMM YYYY") }}</div>
									<div class="name">{{ improvement.user.first_name + ' ' + (improvement.user.last_name ? improvement.user.last_name : '') }}</div>
								</div>
							</div>
							<div class="dropdown dropdown-status" v-if="improvementsData.rights.entity_crud">
								<div class="status" :ref="'dropdownEditRef'+improvement.id" :id="'dropdownEdit'+improvement.id" data-toggle="dropdown">
									<div class="text">{{ $t('improvements.status.name') }}: <div class="value">{{ $t('improvements.status.' + improvement.status) }}</div></div>
								</div>

								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ improvement.id " :aria-labelledby="'dropdownEdit'+improvement.id">
									<div class="dropdown-item" v-if="improvement.status != 'active'" @click="showModal('change_improvement_status', {id: improvement.id, status: 'active', from: 'list'})">
										<div class="simple-text">{{ $t('improvements.action.activate') }}</div>
									</div>
									<div class="dropdown-item" v-if="improvement.status != 'archived'" @click="showModal('change_improvement_status', {id: improvement.id, status: 'archived', from: 'list'})">
										<div class="simple-text">{{ $t('improvements.action.archived') }}</div>
									</div>
									<div class="dropdown-item" v-if="improvement.status != 'featuring'" @click="showModal('change_improvement_status', {id: improvement.id, status: 'featuring', from: 'list'})">
										<div class="simple-text">{{ $t('improvements.action.featuring') }}</div>
									</div>
								</div>
							</div>
							<div class="dropdown-status" v-else>
								<div class="status">
									<div class="text">{{ $t('improvements.status.name') }}: <div class="value">{{ $t('improvements.status.' + improvement.status) }}</div></div>
								</div>
							</div>
						</div>
						<div class="improvement-data">
							<div class="body" v-html="improvement.body"></div>
							<div class="files-data" :class="{empty: !improvement.files.length}">
								<div class="file" v-for="file in improvement.files">
									<a class="file-box" :href="file.file_path" target="_blank">
										<div class="icon"><icon-file /></div>
										<div class="name">{{ file.filename }}</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row-space-tbf list-empty" v-else>
				<div class="content full">
					<div class="title">{{ $t('improvements.empty-inline-improvements')}}</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
	import IconUser from '../../Icons/User'
	import IconFile from '../../Icons/File'

	export default {
		components: {
			IconUser,
			IconFile
		},
		data() {
			return {
				loaded: false,
				improvementsData: ''
			}
		},
		async mounted(){
			this.$root.$on('refreshListImprovements', () => {
				if(this.$route.name == 'process-improvements'){
					this.getProcess()
				}else if(this.$route.name == 'procedure-improvements'){
					this.getProcedure()
				}
			})

			if(this.$route.name == 'process-improvements'){
				await this.getProcess()
			}else if(this.$route.name == 'procedure-improvements'){
				await this.getProcedure()
			}
		},
		watch:{
            $route (to, from){
            	if(this.$route.name == 'process-improvements'){
            		this.getProcess()
            	}else if(this.$route.name == 'procedure-improvements'){
            		this.getProcedure()
            	}
            }
        },
		methods: {
			async getProcedure(){
				await axios.get(`procedures/${this.$route.params.slug}/improvement-proposals`)
				.then(({data}) => {
					this.improvementsData = data.data
				})
				.catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { 
					setTimeout(() => {
						var title = this.improvementsData.name
						var backRoute = 'procedure-show'
						var idOrSlug = this.improvementsData.slug
						this.$root.$emit("navbar_title", title, backRoute, '', idOrSlug);
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					}, 0)
				})
			},
			async getProcess(){
				await axios.get(`processes/${this.$route.params.slug}/improvement-proposals`)
				.then(({data}) => {
					this.improvementsData = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { 
					setTimeout(() => {
						var title = this.improvementsData.name
						var backRoute = 'process-show'
						var idOrSlug = this.improvementsData.slug
						this.$root.$emit("navbar_title", title, backRoute, '', idOrSlug);
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					}, 0)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	}
</script>

<style lang="scss">
    @import "../../../styles/pages/list_improvements.scss";
</style>